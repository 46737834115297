<template>
  <div id="mainColumn1">
    <slideshow-module :slides="slides"></slideshow-module>
    <div
      class="column-row column-row-1"
      style="margin-bottom: 80px; height: 530px"
    >
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content">
          <div class="negative-row">
            <p
              style="display: block; margin: 0px 10px 20px; color: #888888"
              align="left"
            >
              <!-- UniverseTheGame picEABA28F126513C3E5E1D7C6372C1FBD1.png -->
              <img
                style="padding-top: 40px"
                alt=""
                src="@/assets/the-game/playing.png"
                width="269"
                hspace="0"
                height="175"
                border="0"
                align="left"
              />
            </p>
            <div style="display: block; margin: 0px 10px 20px; color: #888888">
              <h2 style="font-size: 24px">Playing LEGO Universe</h2>
            </div>
            <div style="display: block; margin: 0px 10px 20px; color: #888888">
              LEGO Universe is under attack by a devastating Maelstrom that
              seeks to destroy imagination! The time has come for LEGO fans to
              stand united against this evil menace so peace and imagination
              once again can be restored in the Universe, but dangerous times
              lie ahead as the Universe is filled with dark creatures seeking to
              stop you on your quest. Collect gear and weapons as you take on
              laser blasting Spiderlings, spinning Ninjago skeletons,
              fire-breathing dragons and Stromling Pirates as you try to save
              Imagination!<br />
              <br />
              <br />
              <br />
              Complete hundreds of missions and achievements as you travel to
              distant worlds. Visit the tropical world of Gnarled Forest
              inhabited by pirates, blast off to Crux Prime and learn how to
              master Spinjitzu or travel to the Forbidden Valley. The Universe
              awaits you!
              <br />
              <br />
              <br />
              <br />
              <br />
              <!-- UniverseTheGame picE6DC8E401FF4D3BB563C614F6DCA1E73.png -->
              <img
                alt=""
                src="@/assets/the-game/racing.png"
                width="269"
                hspace="0"
                height="159"
                border="0"
                align="right"
              />
            </div>
            <div style="display: block; margin: 0px 10px 20px; color: #888888">
              <h2 style="font-size: 24px">Racing in LEGO Universe</h2>
              In LEGO Universe you can custom build your very own LEGO race car
              with over a hundred customizable parts to choose from, fasten your
              seatbelt and get ready to race the Vertigo Loop racetrack, the
              Keelhaul Canyon Raceway and the Dragonmaw Chasm. Scattered along
              the tracks are obstacles and boosts that will make your car even
              faster during a race. The fastest Minifigs win cool prizes like
              new models and LEGO Universe racing gear!<br />
              <br />
              Start your LEGO engine!
              <br /><br /><br />
              <br />
              <br />
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slideshow from "@/components/Slideshow.vue";

export default {
  data() {
    return {
      slides: [
        {
          // UniverseSlideShowItems downloadA4EA513617454A38DD640994F98C3A2E.swf
          // GUESS!
          img: require("@/assets/slideshow-items/playing.jpg"),
          video: require("@/assets/slideshow-items/playing.mp4"),
          videoPos: "B",
          headline1: "In-Game Video",
          headline2: "Playing with your friends",
          text1: "Form teams with your friends to battle the biggest and baddest Stromlings! Daring and speed get you and your teammates leaderboard status and more LEGO loot!",
          link: "/media/s/141010/c/194313/id/194358",
          linkText: "Watch the video",
        },
      ],
    };
  },
  components: {
    Slideshow,
  },
};
</script>